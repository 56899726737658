import { defineStore } from "pinia";
import { useWeb3 } from "web3";
import { RiseID__factory } from "~/.nuxt/contracts/src/codegen/abi_types/factories/RiseID__factory";

const web3 = useWeb3();

const factories = {
  RiseID: RiseID__factory,
};

export const useWeb3Store = defineStore("web3", {
  state: (): { connectModal: boolean; signData: boolean } => ({
    connectModal: false,
    signData: false,
  }),
  actions: {
    connect() {
      this.connectModal = true;
    },
    async contract(name: keyof typeof factories, address: string) {
      return factories[name].connect(address);
    },
    async getAddress() {
      const address = await web3.getAddress();
      console.log(address);
      return address;
    },
    async signTypedData() {
      this.signData = true;
      await web3.signTypedData({
        domain: {
          chainId: 421614,
          name: "Example App",
          verifyingContract: "0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC",
          version: "1",
        },
        message: {
          prompt:
            "Welcome! In order to authenticate to this website, sign this request and your public address will be sent to the server in a verifiable way.",
          createdAt: `${Date.now()}`,
        },
        primaryType: "AuthRequest",
        types: {
          AuthRequest: [
            { name: "prompt", type: "string" },
            { name: "createdAt", type: "uint256" },
          ],
        },
      });
      this.signData = false;
    },
  },
});
