/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type { RiseID, RiseIDInterface } from "../RiseID.js";

const _abi = [
  {
    type: "constructor",
    inputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "Rise_InvalidRequest",
    type: "error",
    inputs: [],
  },
  {
    name: "Rise_InvalidRequestWithReason",
    type: "error",
    inputs: [
      {
        name: "reason",
        type: "string",
        internalType: "string",
      },
    ],
  },
  {
    name: "Rise_InvalidRequest_Amount",
    type: "error",
    inputs: [],
  },
  {
    name: "Rise_InvalidRequest_Payable",
    type: "error",
    inputs: [],
  },
  {
    name: "Rise_InvalidRequest_Payee",
    type: "error",
    inputs: [],
  },
  {
    name: "Rise_InvalidRequest_Payer",
    type: "error",
    inputs: [],
  },
  {
    name: "Rise_Unauthorized",
    type: "error",
    inputs: [
      {
        name: "caller",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    name: "ContractCreated",
    type: "event",
    inputs: [
      {
        name: "operationType",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "contractAddress",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "value",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "salt",
        type: "bytes32",
        indexed: false,
        internalType: "bytes32",
      },
    ],
    anonymous: false,
  },
  {
    name: "DataChanged",
    type: "event",
    inputs: [
      {
        name: "dataKey",
        type: "bytes32",
        indexed: true,
        internalType: "bytes32",
      },
      {
        name: "dataValue",
        type: "bytes",
        indexed: false,
        internalType: "bytes",
      },
    ],
    anonymous: false,
  },
  {
    name: "EIP712DomainChanged",
    type: "event",
    inputs: [],
    anonymous: false,
  },
  {
    name: "Executed",
    type: "event",
    inputs: [
      {
        name: "operationType",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "target",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "value",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "selector",
        type: "bytes4",
        indexed: false,
        internalType: "bytes4",
      },
    ],
    anonymous: false,
  },
  {
    name: "Initialized",
    type: "event",
    inputs: [
      {
        name: "version",
        type: "uint8",
        indexed: false,
        internalType: "uint8",
      },
    ],
    anonymous: false,
  },
  {
    name: "RiseIDDelegateAdded",
    type: "event",
    inputs: [
      {
        name: "user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    name: "RiseIDDelegateRemoved",
    type: "event",
    inputs: [
      {
        name: "user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    name: "RiseIDOwnerAdded",
    type: "event",
    inputs: [
      {
        name: "user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    name: "RiseIDOwnerRemoved",
    type: "event",
    inputs: [
      {
        name: "user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    name: "approve",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "token",
            type: "address",
            internalType: "address",
          },
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
        ],
        internalType: "struct RiseIDRequests.Approve",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "approve",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "incOrDec",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "token",
            type: "address",
            internalType: "address",
          },
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
        ],
        internalType: "struct RiseIDRequests.ApprovalChange",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "call",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
          {
            name: "to",
            type: "address",
            internalType: "address",
          },
          {
            name: "method",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "data",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        internalType: "struct RiseIDRequests.Execution",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "eip712Domain",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "fields",
        type: "bytes1",
        internalType: "bytes1",
      },
      {
        name: "name",
        type: "string",
        internalType: "string",
      },
      {
        name: "version",
        type: "string",
        internalType: "string",
      },
      {
        name: "chainId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "verifyingContract",
        type: "address",
        internalType: "address",
      },
      {
        name: "salt",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "extensions",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "execute",
    type: "function",
    inputs: [
      {
        name: "operationType",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "target",
        type: "address",
        internalType: "address",
      },
      {
        name: "value",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "data",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    stateMutability: "payable",
  },
  {
    name: "execute",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
          {
            name: "to",
            type: "address",
            internalType: "address",
          },
          {
            name: "method",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "data",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        internalType: "struct RiseIDRequests.Execution",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "executeBatch",
    type: "function",
    inputs: [
      {
        name: "operationsType",
        type: "uint256[]",
        internalType: "uint256[]",
      },
      {
        name: "targets",
        type: "address[]",
        internalType: "address[]",
      },
      {
        name: "values",
        type: "uint256[]",
        internalType: "uint256[]",
      },
      {
        name: "datas",
        type: "bytes[]",
        internalType: "bytes[]",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bytes[]",
        internalType: "bytes[]",
      },
    ],
    stateMutability: "payable",
  },
  {
    name: "getData",
    type: "function",
    inputs: [
      {
        name: "dataKey",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    outputs: [
      {
        name: "dataValue",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "getDataBatch",
    type: "function",
    inputs: [
      {
        name: "dataKeys",
        type: "bytes32[]",
        internalType: "bytes32[]",
      },
    ],
    outputs: [
      {
        name: "dataValues",
        type: "bytes[]",
        internalType: "bytes[]",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "getDelegates",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address[]",
        internalType: "address[]",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "getDelegatesLength",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "getOwners",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address[]",
        internalType: "address[]",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "getOwnersLength",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "hashExecuted",
    type: "function",
    inputs: [
      {
        name: "structHash",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "init",
    type: "function",
    inputs: [
      {
        name: "_newOwner",
        type: "address",
        internalType: "address",
      },
      {
        name: "_recoveryAddr",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "initData",
    type: "function",
    inputs: [
      {
        name: "_data",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "isDelegate",
    type: "function",
    inputs: [
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "isOwner",
    type: "function",
    inputs: [
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "isValidSignature",
    type: "function",
    inputs: [
      {
        name: "hash",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [
      {
        name: "magicValue",
        type: "bytes4",
        internalType: "bytes4",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "recoverOwnership",
    type: "function",
    inputs: [
      {
        name: "newOwner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "riseRecoveryAddress",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    name: "setData",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
          {
            name: "dataKey",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "dataValue",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        internalType: "struct RiseIDRequests.Dataset",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "setData",
    type: "function",
    inputs: [
      {
        name: "dataKey",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "dataValue",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    name: "setDataBatch",
    type: "function",
    inputs: [
      {
        name: "dataKeys",
        type: "bytes32[]",
        internalType: "bytes32[]",
      },
      {
        name: "dataValues",
        type: "bytes[]",
        internalType: "bytes[]",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    name: "setDelegate",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "addOrRemove",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "account",
            type: "address",
            internalType: "address",
          },
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
        ],
        internalType: "struct RiseIDRequests.Delegate",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "setNewRecoveryAddress",
    type: "function",
    inputs: [
      {
        name: "_riseRecoveryAddr",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "setOwner",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "addOrRemove",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "account",
            type: "address",
            internalType: "address",
          },
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
        ],
        internalType: "struct RiseIDRequests.Owner",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "supportsInterface",
    type: "function",
    inputs: [
      {
        name: "interfaceId",
        type: "bytes4",
        internalType: "bytes4",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "pure",
  },
  {
    name: "transfer",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "token",
            type: "address",
            internalType: "address",
          },
          {
            name: "to",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
        ],
        internalType: "struct RiseIDRequests.Transfer",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "transferFrom",
    type: "function",
    inputs: [
      {
        name: "req",
        type: "tuple",
        components: [
          {
            name: "token",
            type: "address",
            internalType: "address",
          },
          {
            name: "from",
            type: "address",
            internalType: "address",
          },
          {
            name: "to",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "request",
            type: "tuple",
            components: [
              {
                name: "expires",
                type: "uint64",
                internalType: "uint64",
              },
              {
                name: "signer",
                type: "address",
                internalType: "address",
              },
            ],
            internalType: "struct RiseRequests.RiseRequest",
          },
        ],
        internalType: "struct RiseIDRequests.TransferFrom",
      },
      {
        name: "signature",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
] as const;

export class RiseID__factory {
  static readonly abi = _abi;
  static createInterface(): RiseIDInterface {
    return new Interface(_abi) as RiseIDInterface;
  }
  static connect(address: string, runner?: ContractRunner | null): RiseID {
    return new Contract(address, _abi, runner) as unknown as RiseID;
  }
}
