import RSK from "rsk";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import { GetWalletClientResult, SignTypedDataArgs } from "@wagmi/core";
import { arbitrumSepolia } from "@wagmi/core/chains";
import { MetaMaskConnector } from "@wagmi/connectors/metaMask";
import { CoinbaseWalletConnector } from "@wagmi/connectors/coinbaseWallet";
import {
  getWalletClient,
  configureChains,
  createConfig,
  disconnect,
  connect,
  switchNetwork,
} from "@wagmi/core";
import { Buffer } from "buffer";

if (!window.Buffer) {
  window.Buffer = Buffer;
}

const chains = [arbitrumSepolia];
const projectId = "1a013d19cadf19f0e6f40810f9b81a8c";

const mmConnector = new MetaMaskConnector({ chains });
const cbConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: "Riseworks",
  },
});
const rskSdk = RSK();

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const connectors = {
  metamask: mmConnector,
  coinbase: cbConnector,
};

const ethereumClient = new EthereumClient(wagmiClient, chains);
const web3modal = new Web3Modal(
  {
    projectId,
    explorerRecommendedWalletIds: [
      "3fecad5e2f0a30aba97edea69ebf015884a9b8a9aec93e66d4b4b695fee1f010",
      "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d",
    ],
    explorerExcludedWalletIds: "ALL",
  },
  ethereumClient
);
web3modal.setDefaultChain(arbitrumSepolia);

let walletClient: GetWalletClientResult | null = null;

export const useWeb3 = () => ({
  async connect(type: string, email?: string) {
    walletClient = await getWalletClient();
    if (walletClient) {
      await disconnect();
      walletClient = null;
    }
    if (type === "rsk") {
      await rskSdk.connect();
    } else if (["metamask", "coinbase"].includes(type)) {
      await connect({
        chainId: arbitrumSepolia.id,
        connector: connectors[type as keyof typeof connectors],
      });
      walletClient = await getWalletClient({ chainId: arbitrumSepolia.id });
    } else if (type === "walletconnect") {
      await new Promise((resolve, reject) => {
        web3modal.openModal().catch((e) => console.log(e));
        const unsubscribe = web3modal.subscribeModal(async (v: any) => {
          if (v.open) return;
          walletClient = await getWalletClient();
          resolve(true);
          unsubscribe();
        });
      });
    }
    return;
  },
  disconnect() {
    walletClient = null;
    return disconnect();
  },
  async getAddress() {
    if (await rskSdk.getAddress()) {
      return rskSdk.getAddress();
    }
    walletClient = await getWalletClient({ chainId: arbitrumSepolia.id });
    if (!walletClient) {
      throw new Error("Wallet not connected");
    }
    return walletClient.account;
  },
  async signTypedData(params: SignTypedDataArgs) {
    if (await rskSdk.getAddress()) {
      return rskSdk.signTypedData(params);
    }
    walletClient = await getWalletClient({ chainId: arbitrumSepolia.id });
    if (!walletClient) {
      throw new Error("Wallet not connected");
    }
    return walletClient.signTypedData(params);
  },
});
