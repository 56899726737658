import {
  generateAuthenticationOptions,
  generateRegistrationOptions,
} from "@simplewebauthn/server";
import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";

export async function generateKey(email: string) {
  const registrationOptions = await generateRegistrationOptions({
    rpName: "Rise Security Key Manager",
    rpID: window.location.hostname,
    userID: `Rise Passkey - ${email}`,
    userName: `Rise Passkey - ${email}`,
  });

  const authn = await startRegistration(registrationOptions);
  return authn.id;
}

export async function getKey() {
  const options = await generateAuthenticationOptions({
    userVerification: "preferred",
    rpID: `${window.location.hostname}`,
  });
  const { id } = await startAuthentication(options);
  return id;
}
